export const CAMPAIGN_ITEM = [
  {
    id: "1",
    title: "Xây dựng điểm trường",
    description:
      "The consensus mechanism that connects Stacks and Bitcoin, allowing applications to benefit from the security of Bitcoin.",
    thumbnailUrl:
      "https://images.pexels.com/photos/674010/pexels-photo-674010.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    id: "2",
    title: "Xây dựng điểm trường",
    description:
      "The consensus mechanism that connects Stacks and Bitcoin, allowing applications to benefit from the security of Bitcoin.",
    thumbnailUrl:
      "https://images.pexels.com/photos/674010/pexels-photo-674010.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    id: "3",
    title: "Xây dựng điểm trường",
    description:
      "The consensus mechanism that connects Stacks and Bitcoin, allowing applications to benefit from the security of Bitcoin.",
    thumbnailUrl:
      "https://images.pexels.com/photos/674010/pexels-photo-674010.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
];

export const PROJECTS = [
  {
    id: "1",
    title: "ĐIỂM TRƯỜNG HÚI TO",
    location: "Xã Chùng Chải - Mường Nhé",
    price: 222222222,
    goal:0,
    totalDonate: 0,
    participantsCount: 300,
    description: "Xây gạch bao che, Xây gạch bao che, Xây gạch bao che",
    startDate: "2023/06/20",
    endDate: "2024/06/20",
    campaign: {
      id: "1",
      name: "Góp lẻ xây trường",
    },
  },
  {
    id: "2",
    title: "ĐIỂM TRƯỜNG HÚI TO",
    location: "Xã Chùng Chải - Mường Nhé",
    price: 222222222,
    participantsCount: 300,
    description: "Xây gạch bao che, Xây gạch bao che, Xây gạch bao che",
    startDate: "2023/06/20",
    endDate: "2024/06/20",
    campaign: {
      id: "1",
      name: "Góp lẻ xây trường",
    },
  },
  {
    id: "3",
    title: "ĐIỂM TRƯỜNG HÚI TO",
    location: "Xã Chùng Chải - Mường Nhé",
    price: 222222222,
    participantsCount: 300,
    description: "Xây gạch bao che, Xây gạch bao che, Xây gạch bao che",
    startDate: "2023/06/20",
    endDate: "2024/06/20",
    campaign: {
      id: "1",
      name: "Góp lẻ xây trường",
    },
  },
  {
    id: "4",
    title: "ĐIỂM TRƯỜNG HÚI TO",
    location: "Xã Chùng Chải - Mường Nhé",
    price: 222222222,
    participantsCount: 300,
    description: "Xây gạch bao che, Xây gạch bao che, Xây gạch bao che",
    startDate: "2023/06/20",
    endDate: "2024/06/20",
    campaign: {
      id: "1",
      name: "Góp lẻ xây trường",
    },
  },
  {
    id: "5",
    title: "ĐIỂM TRƯỜNG HÚI TO",
    location: "Xã Chùng Chải - Mường Nhé",
    price: 222222222,
    participantsCount: 300,
    description: "Xây gạch bao che, Xây gạch bao che, Xây gạch bao che",
    startDate: "2023/06/20",
    endDate: "2024/06/20",
    campaign: {
      id: "1",
      name: "Góp lẻ xây trường",
    },
  },
  {
    id: "6",
    title: "ĐIỂM TRƯỜNG HÚI TO",
    location: "Xã Chùng Chải - Mường Nhé",
    price: 222222222,
    participantsCount: 300,
    description: "Xây gạch bao che, Xây gạch bao che, Xây gạch bao che",
    startDate: "2023/06/20",
    endDate: "2024/06/20",
    campaign: {
      id: "1",
      name: "Góp lẻ xây trường",
    },
  },
];

export const TEAMS = [
  {
    id: "1",
    avatarUrl:
      "https://web.sucmanh2000.com/static/media/Hoang_Hoa_Trung.74f61c53d337055f9482.png?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    name: "Hoàng Hoa Trung",
    description: "Người thành lập Sức mạnh 2000 và Hệ sinh thái Nuôi em",
  },
  {
    id: "2",
    avatarUrl: "https://web.sucmanh2000.com/static/media/Nguyen_Thi_Hien.84bf94c2449b0bba11d0.png",
    name: "Nguyễn Thị Hiền",
    description: "Phó Chủ Nhiệm Dự Án Sức mạnh 2000",
  },
  {
    id: "3",
    avatarUrl: "https://web.sucmanh2000.com/static/media/Do_Thi_Kim_Hoa.b3cdbd738d793d76ce0d.png",
    name: "ĐỖ THỊ KIM HOA",
    description: "Giám đốc trung tâm tình nguyện quốc gia",
  },
];
