import React from "react";

import image from "../../../assets/images/bridge.jpg";
import school from "../../../assets/images/school.jpg";
import home from "../../../assets/images/home.jpg";
import bgImage from "../../../assets/images/user-banner.jpg";
import { useNavigate } from "react-router-dom";

const AboutChallenge = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/dang-ky");
  };
  return (
    <div className="bg-white">
      <header
        className="relative text-center py-20 bg-cover bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative z-10">
          <h1 className="text-4xl font-bold mb-6 text-white">
            Trở thành đại sứ của Sức mạnh 2000 chưa bao giờ dễ dàng đến thế
          </h1>
          <p className="text-lg max-w-2xl mx-auto mb-8 text-white">
            Chỉ mất chưa đầy 5 phút, bạn có thể trở thành đại sứ với việc tạo ra
            một không gian giây quỹ nơi đó bạn có thể chọn dự án mình muốn gây
            quỹ, đặt mục tiêu, rủ bạn bè gây quỹ và được ghi nhận số tiền minh
            bạch ngay lập tức.
          </p>
          <button
            className="bg-primary text-white py-3 px-6 rounded-full text-lg"
            onClick={handleClick}
          >
            Đăng ký
          </button>
        </div>
      </header>
      <div className="container mx-auto">
        {/* Feature Sections */}
        <section className="flex justify-center mt-16">
          <div className="text-center mx-4 flex-1">
            <h3 className="text-xl font-semibold mb-4">
              Đại sứ gây quỹ Sức mạnh 2000
            </h3>
            <p className="mb-6">
              Dành cho những người tạo trang riêng dành cho bạn và nhóm bạn của
              mình, sẵn sàng kêu gọi cho một dự án đang gây quỹ lẻ trên website
              Sức mạnh 2000
            </p>
          </div>
          <div className="text-center mx-4 flex-1">
            <h3
              className="text-xl font-semibold mb-4 hover:underline hover:cursor-pointer"
              onClick={() => navigate("/xep-hang")}
            >
              Xếp hạng và vinh danh
            </h3>
            <p className="mb-6">
              Sức mạnh 2000 ghi nhận những đóng góp nhỏ bé nhưng tạo nên sức
              mạnh phi thường, chúng tôi vinh danh những đội nhóm đoàn kết và
              hoạt động nhiệt tình nhất trong việc gây quỹ
            </p>
          </div>
          <div className="text-center mx-4 flex-1 ">
            <h3
              className="text-xl font-semibold mb-4 hover:underline  hover:cursor-pointer"
              onClick={() => navigate("/dai-su")}
            >
              Danh sách các đại sứ
            </h3>
            <p className="mb-6">
              Nơi liệt kê toàn bộ những đại sứ và nhóm bạn của mình đang tham
              gia gây quỹ, rõ ràng, minh bạch, kịp thời và đầy cảm xúc.
            </p>
          </div>
        </section>

        {/* Campaign Examples */}
        <section className="mt-20">
          <div className="max-w-5xl mx-auto">
            <div className="flex flex-wrap">
              <div className="w-full md:w-1/2 p-4">
                <img
                  src={school}
                  alt="Engage Your School"
                  className="rounded-lg mb-4"
                />
              </div>
              <div className="w-full md:w-1/2 p-4">
                <h3 className="text-xl font-semibold mb-2">
                  Thử thách tiền lẻ Sức mạnh 2000
                </h3>
                <p className="mb-4 ">
                  Chúng tôi mong muốn rằng, ngay cả bác bảo vệ, cô bán rau, hay
                  chị lao công, với những lao động trân quý và đầy vinh quang
                  đó, cũng có thể tham gia thiện nguyện bằng cách góp 2000đ MỖI
                  NGÀY. Và thật tuyệt, nếu có hàng triệu người tham gia, con số
                  đoàn kết đó có thể khiến bạn giật mình: Với 1 triệu người,
                  chúng ta có 720 tỉ đồng/năm đủ sức xây và sửa hết TOÀN BỘ điểm
                  trường tạm, xuống cấp trên Việt Nam. Hãy bắt đầu là những
                  người đầu tiên cho câu chuyện cổ tích này!
                </p>
              </div>
            </div>

            <div className="flex flex-wrap">
              <div className="w-full md:w-1/2 p-4">
                <h3 className="text-xl font-semibold mb-2">
                  QR code tự động thay đổi
                </h3>
                <p className="mb-4 text-justify">
                  Điểm mới lạ và cực kì minh bạch được trang bị cho đại sứ gây
                  quỹ đó là QR code động tự thay đổi mỗi khi nhập số tiền và nội
                  dung, từ đó khiến 100% số tiền đến đúng dự án định danh một
                  cách rõ ràng và NGAY LẬP TỨC!
                </p>
              </div>
              <div className="w-full md:w-1/2 p-4">
                <img
                  src={image}
                  alt="Calling All Athletes!"
                  className="rounded-lg mb-4"
                />
              </div>
            </div>

            <div className="flex flex-wrap mt-8">
              <div className="w-full md:w-1/2 p-4">
                <img
                  src={home}
                  alt="Donate Your Birthday"
                  className="rounded-lg mb-4"
                />
              </div>
              <div className="w-full md:w-1/2 p-4">
                <h3 className="text-xl font-semibold mb-2">
                  Mỗi dự án trong 600 dự án đã thực hiện vào phần chi tiết đều
                  có:
                </h3>
                <p className="mb-4">
                  - 3 Album trước, trong, và sau khi thực hiện <br />
                  - Giới thiệu kèm logo của nhà tài trợ lớn hoặc chi tiết sao kê
                  từng nghìn lẻ của mỗi nhà hảo tâm
                  <br /> - Giới thiệu chi tiết khảo sát ban đầu và kèm theo SĐT
                  Thầy giáo, Hiệu trưởng, PGD kèm theo để minh bạch thông tin
                  <br /> - Nhu cầu xây dựng và Thông tin xây dựng thực tế
                  <br /> - Cùng nhiều chi tiết khác...
                </p>
                <button
                  className="bg-primary text-white py-2 px-4 rounded-full"
                  onClick={handleClick}
                >
                  Đăng ký
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AboutChallenge;
